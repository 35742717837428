import { Component, ComponentProps } from "solid-js";
import { Dynamic } from "solid-js/web";
import { A } from "@solidjs/router";
import c from "class-c";
import { Event, eventHandler } from "solid-u";

import UFunction from "@repo/utils/UFunction";
import createPropsProvider from "@repo/utils-solid/createPropsProvider";

import Identity from "../flow/Identity";

import styles from "./Link.module.scss";

declare namespace Link {
  type Props = ComponentProps<typeof A> & {
    // Not required by default
    href: string;
    variant?: "container" | "unstyled";
    // typeof A["state"] is unknown, causes type error
    state?: undefined;
  };
}

function Link(_props: Link.Props) {
  const {
    children,
    variant = "unstyled" as never,
    as = A,
    class: className,
    onClick,
    ...props
  } = Link.PropsProvider.useMerge(_props) satisfies D;

  return (
    <Dynamic
      component={as}
      class={c`
        ${styles[`variant:${variant}`]}
        ${className}
      `}
      onClick={(e: Event.FromComponent<typeof A, "onClick">) => {
        // TODO: All navigations must be tracked with AppRouter so we can compute history
        // AppRouter[props.replace ? "replace" : "push"](props.href);

        eventHandler(onClick)(e);
      }}
      {...props}
    >
      {children}
    </Dynamic>
  );
}

declare namespace Link.If {
  type Props = Link.Props & {
    when: unknown;
  };
}

Link.If = ({ when, children, ...props }: D<Link.If.Props>) => {
  return (
    <Dynamic component={when ? Link : Identity} {...props}>
      {children}
    </Dynamic>
  );
};

Link.PropsProvider = createPropsProvider<
  Link.Props & {
    as?: Component<Partial<ComponentProps<"a">> & { href: string }> | "a";
    classBy?(props: Link.Props): string;
  }
>("Link", {
  class: (context, props) =>
    c`${context.class} ${props.class} ${context.classBy?.(props)}`,
});

declare namespace Link.Doc {
  type Props = Link.Props & { unstyled?: boolean };
}

Link.Doc = Doc;
function Doc(_props: Link.Doc.Props) {
  const {
    href,
    class: className,
    transformHref = UFunction.identity,
    unstyled,
    ...props
  } = Doc.PropsProvider.useMerge(_props) satisfies D;

  return (
    <Link
      target="_blank"
      class={c`${!unstyled && "underline"} ${className}`}
      href={transformHref(href)}
      {...props}
    />
  );
}

Doc.PropsProvider = createPropsProvider<
  Link.Props & {
    transformHref(href: string): string;
  }
>("Link.Doc");

declare namespace Link.External {
  type Props = Link.Props;
}

Link.External = External;
function External(props: Link.External.Props) {
  return <Link target="_blank" {...props} />;
}

export default Link;
